import React from 'react'
import styled from 'styled-components'
import Number from '../../components/Number';


function ValueGrid({label, children}) {

    return <div className="column value-grid">
        <p className="color-gray">{label}</p>
        <div className="spacer xs"></div>
        <div className="grid" columns="3">
            {children}
        </div>
    </div>
}

export default function StockCard({data}) {


    let standardStoreData = data?.stores?.find(s => s.type == 'STANDARD');
    standardStoreData = standardStoreData ? standardStoreData : {}


    let premiumStoreData = data?.stores?.find(s => s.type == 'PREMIUM');
    premiumStoreData = premiumStoreData ? premiumStoreData : {}


    let totalStoreData = data?.stores?.find(s => s.type == 'TOTAL');
    totalStoreData = totalStoreData ? totalStoreData : {}

    // Sep 2021 )mellan 10 sep 2021 och 29/2  så är 242 alltid "From Sep 2022”  
    // Efter 1/3 så är översta rubriken istället Mars 2023. 

    let today = new Date();
    let year = today.getFullYear();

    let period = 1;

    if (
        (today.isAfter( new Date(`${year}-09-10`)) &&
        today.isBefore(new Date(`${year + 1}-02-29`))) 
        
        ||

        (today.isAfter( new Date(`${year-1}-12-31`)) &&
        today.isBefore(new Date(`${year}-02-29`)))
    ) {
        period = 0;
    }

    console.log(period)

    let heading1 = period == 0 ? `From Sep ${year}` : `From Mars ${year + 1}`;
    let heading2 = period == 0 ? `From Mars ${year}` : `From Sep ${year}`;
    let heading3 = 'Current';

    return (
        <Style className="stock-card card">
            <div className="stock">
                <h2 className="color-blue-gray">Distribution</h2>

                <div className="spacer m"></div>

                <div className="group-grid">

                    
                    <ValueGrid label="standard stores">
                        <span className="number color-gray">{heading1}</span>
                        <span className={`number`}><Number percentage={true}>{standardStoreData.prognosed_percentage}</Number></span>
                        <span className={`number`}><Number>{standardStoreData.prognosed_value}</Number></span>

                        <span className="number color-gray">{heading2}</span>
                        <span className={`number`}><Number percentage={true}>{standardStoreData.upcomming_percentage}</Number></span>
                        <span className={`number`}><Number>{standardStoreData.upcomming_value}</Number></span>

                        <span className="number color-gray">{heading3}</span>
                        <span className={`number`}><Number percentage={true}>{standardStoreData.current_percentage}</Number></span>
                        <span className={`number`}><Number>{standardStoreData.current_value}</Number></span>
                    </ValueGrid>


                    
                    <ValueGrid label="premium stores">
                        <span className="number color-gray">{heading1}</span>
                        <span className={`number`}><Number percentage={true}>{premiumStoreData.prognosed_percentage}</Number></span>
                        <span className={`number`}><Number>{premiumStoreData.prognosed_value}</Number></span>

                        <span className="number color-gray">{heading2}</span>
                        <span className={`number`}><Number percentage={true}>{premiumStoreData.upcomming_percentage}</Number></span>
                        <span className={`number`}><Number>{premiumStoreData.upcomming_value}</Number></span>

                        <span className="number color-gray">{heading3}</span>
                        <span className={`number`}><Number percentage={true}>{premiumStoreData.current_percentage}</Number></span>
                        <span className={`number`}><Number>{premiumStoreData.current_value}</Number></span>
                    </ValueGrid>


                    
                    <ValueGrid label="total stores">
                        <span className="number color-gray">{heading1}</span>
                        <span className={`number`}><Number percentage={true}>{totalStoreData.prognosed_percentage}</Number></span>
                        <span className={`number`}><Number>{totalStoreData.prognosed_value}</Number></span>

                        <span className="number color-gray">{heading2}</span>
                        <span className={`number`}>
                            <div className="main-number">
                                <Number percentage={true}>{totalStoreData.upcomming_percentage}</Number>
                            </div>

                            <div className="bottom">
                                {`(${(totalStoreData.coverage_percentage * 100).toFixed(1)}%) ${((totalStoreData.upcomming_percentage + totalStoreData.coverage_percentage) * 100).toFixed(1)}%`}
                            </div>
                        </span>
                        <span className={`number`}><Number>{totalStoreData.upcomming_value}</Number></span>

                        <span className="number color-gray">{heading3}</span>
                        <span className={`number`}>
                            <div className="main-number">
                                <Number percentage={true}>{totalStoreData.current_percentage}</Number>
                            </div>

                            <div className="bottom">
                                {`(${(totalStoreData.current_coverage_percentage * 100).toFixed(1)}%) ${((totalStoreData.current_percentage + totalStoreData.current_coverage_percentage) * 100).toFixed(1)}%`}
                            </div>
                        </span>
                        <span className={`number`}><Number>{totalStoreData.current_value}</Number></span>
                    </ValueGrid>

                </div>
            </div>

            <div className="stores">
                <h2 className="color-blue-gray">Actual Stores</h2>
                <div className="spacer m"></div>
                
                <div className="spaced-row">
                    <p className="color-gray">STORES</p>
                    <p className="color-gray">STORE STOCK</p>
                </div>

                <div className="spacer xs"></div>

                <div className="spaced-row">
                    <h3 className="number"><Number>{data && data.actualStores.actualStores}</Number></h3>
                    <h3 className="number"><Number>{data && data.actualStores.stockInStores}</Number></h3>
                </div>

                <div className="spacer s"></div>

                <div className="spaced-row">
                    <p className="color-gray">STANDARD</p>
                    <p className="color-gray">PREMIUM</p>
                </div>

                <div className="spacer xs"></div>

                <div className="spaced-row">
                    <h3 className="number"><Number>{data && data.actualStores.standardStores}</Number></h3>
                    <h3 className="number"><Number>{data && data.actualStores.premiumStores}</Number></h3>
                </div>
            </div>

        </Style>
    )
}

const Style = styled.div`
    padding: 15rem;

    background: #272727 !important;
    border-color: #343434;

    display: flex;
    flex-direction: row;

    .stock {
        flex: 1;
        margin-right: 30rem;
        width: 75%
        display: flex;
        flex-direction: column;
    }

    .stores {
        width: 25%
    }


    .group-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
            min-width: 200px;
            flex-shrink: 0;
            margin-bottom: 40px;
        }
    }

    .value-grid {
        p {
            text-transform: uppercase;
        }
        & > .grid {

            .is-tv & {
                grid-template-columns: 2fr 3fr 1fr;
            }

            & > *:nth-child(3n + 1) {
                font-size: 8rem;
            }

            & > *:nth-child(3n + 2) {
                text-align: center;

                .bottom {
                    font-size: 8rem;
                }

                .is-tv & {
                    display: flex;
                    align-items: center;
                    gap: 10rem;

                    .bottom {
                        font-size: 13rem;
                    }
                }
            }

            & > *:nth-child(3n) {
                text-align: right;
            }

            .number {
                margin-bottom: 10px;
            }
        }
    }



    .mobile & {
        flex-direction: column;
        .stores, .stock {
            width: 100%;
        }

        .group-grid {
    
            & > * {
                min-width: 100%;
            }
        }
    }

    .is-tv & {
        flex-direction: column;
        .stores, .stock {
            width: 100%;
        }

        .spacer.m {
            height: 6rem;
        }

        .spacer.s {
            height: 4rem;
        }
        .spacer.xs {
            height: 4rem;
        }
    }
`